import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro"; 
import SearchEngineOptimization from "../components/SEO";

import Layout from "../components/Layout";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Page = () => {
  return (
    <Layout>
    <SearchEngineOptimization
        title="Privacy Policy | Cannabis CPA | Dark Horse"
        description="Dark Horse CPAs Privacy Policy and information we collect from our clients."
      />
      <section className="md:pb-30 pt-12 pb-20 md:pt-16">
        <div className="container">
          <StyledContent>
            <h1>Privacy Policy</h1>

            <p>Last updated: March 2022</p>

            <p>
              When you use Dark Horse CPAs, An Accountancy Corporation’s (“we,”
              or “us”) services, you are entrusting us with your information. We
              understand the gravity of this responsibility. We work hard to
              protect your information and to put you in control of your
              information.
            </p>

            <p>
              This Privacy Policy is designed to aid your understanding of what
              information we collect, why we collect it, and how you can manage
              your information, whether through our Services or third party
              services (collectively, the “Services”). We encourage you to read
              the entire Privacy Policy before using our Services. By using the
              Services, you consent to the terms of this Privacy Policy as well
              as our Terms of Service.
            </p>

            <h4>CATEGORIES OF INFORMATION WE COLLECT</h4>
            <p>
              Depending on your relationship with us, we may collect specific
              categories of information about you. Below is a description of
              categories of information that we may collect.
            </p>

            <h5>Client Relationship Information</h5>
            <p>
              This category includes information that we may request from you or
              that which you voluntarily provide us for our records about you as
              a client and the services we provide you. This may include items
              such as your business contact information, your
              background/history, services you have obtained from us, unique
              identification numbers associated with you, and other information
              our cloud servers may gather about you to serve you better, such
              as family members’ names.
            </p>

            <h5>Personal Identifiers and Demographic Information</h5>
            <p>
              This category includes: state and federally protected classes of
              individual information, contact information such as your name,
              phone number and email, and other types of general information
              that may be used to identify you. This category includes items
              such as your city, state, country and time zone. 
            </p>

            <h5>Client Financial Services Information</h5>
            <p>
              This category includes the information we may request from you or
              or that which you voluntarily provide us as part of financial
              services that we provide you, such as tax filings. This
              information may include compensation plans, information necessary
              for tax filings and other state or federal disclosures, corporate
              records, investments, and other related information.
            </p>

            <h5>Employment Information</h5>
            <p>
              This category includes any information related to your previous
              and current employment. This includes your prior employer(s),
              compensation, title, and information found on Forms W2 and other
              related tax documents. Additionally, we may have information for
              direct deposits, information contained in your resume and cover
              letter, and any information related to your application or
              employment at Dark Horse CPAs.
            </p>

            <h5>Education Information</h5>
            <p>
              This category includes information such as educational
              institutions attended, degrees obtained, scholarships awarded,
              graduation date, school email, and grade point average (GPA).
              Typically, we have this information because you applied for a
              position with us. 
            </p>

            <h5>Internet or Network Activities</h5>
            <p>
              This category includes information we gather when you interact
              with our websites or systems, such as internet protocol addresses,
              search history, session IDs, completion of web forms, as well as
              other site usage information gathered through cookies hosted on
              our website. 
            </p>

            <h5>Internal Corporate Information</h5>
            <p>
              This category refers to the information that we may obtain in the
              normal course of performing our internal business operations. This
              may include information about our Service Providers and 3rd Party
              vendors’ employees, business contacts including billing contacts,
              address and location, and services provided.
            </p>

            <h5>Location Data</h5>
            <p>
              This category include things like your city, state, country
              and time zone. 
            </p>

            <h4>BUSINESS PURPOSES FOR INFORMATION USE</h4>
            <p>
              We may use your categories of information for business
              development, marketing and virtual event management. This business
              purpose includes activities such as:
            </p>

            <ul>
              <li>Lead generation</li>
              <li>
                Marketing campaigns by mail or email or through our website
              </li>
              <li>Travel and expense reporting</li>
              <li>Hosting internal conferences and training for employees</li>
              <li>
                Hosting or sponsoring events for our employees, clients, and the
                general public 
              </li>
            </ul>

            <p>
              We may use your categories of information to aid in the provision
              of services to our clients. This business purpose includes
              activities such as:
            </p>

            <ul>
              <li>Tax Preparation &amp; Filings</li>
              <li>Client Services &amp; Invoicing</li>
              <li>Outsourced Business Services</li>
              <li>User Registration &amp; Access Rights Monitoring</li>
            </ul>

            <p>
              We may use your categories of information to comply with our
              legal, professional and regulatory obligations. This business
              purpose includes activities such as:
            </p>

            <ul>
              <li>Corporate insurance management</li>
              <li>Payroll processing, reporting, and administration</li>
              <li>Data loss prevention and security monitoring</li>
              <li>Background screening</li>
              <li>Professional certifications and ethical compliance</li>
              <li>
                Personnel and incident investigations &amp; litigation matters
              </li>
              <li>Regulatory &amp; government compliance matters</li>
              <li>Government requests and subpoena responses</li>
            </ul>

            <p>
              We may use your categories of information so that we can manage
              our relationship with you as a client. This business purpose
              includes activities such as:
            </p>

            <ul>
              <li>Client on-/off-boarding &amp; communication outreach</li>
              <li>Background screening</li>
              <li>
                Contract engagement, engagement letters, and other contractual
                obligations
              </li>
              <li>Event management &amp; registration</li>
            </ul>

            <p>
              We may use your categories of information ensure the physical and
              electronic security of Dark Horse CPAs. This business purpose
              includes activities such as:
            </p>

            <ul>
              <li>Mobile device management</li>
              <li>Physical, technical, and administrative security reviews</li>
              <li>Network monitoring &amp; online intrusion detection</li>
              <li>Digital user registration &amp; access monitoring</li>
            </ul>

            <p>
              We may use your categories of information to ensure we are in
              compliance with our obligations to our employees. This business
              purpose includes activities such as:
            </p>

            <ul>
              <li>Credit review and background screening</li>
              <li>
                Personnel compensation, travel, and expense reporting &amp;
                reimbursement
              </li>
              <li>Employee insurance, benefits, and leaves of absence</li>
              <li>Employee management, lifecycle &amp; records</li>
            </ul>

            <p>
              We may use your categories of information as part of managing our
              service providers and third party vendors. This business purpose
              includes activities such as:
            </p>

            <ul>
              <li>Vendor assessments &amp; security evaluations</li>
              <li>
                Vendor agreement negotiations, executions, and implementations
              </li>
              <li>Order processing</li>
              <li>Vendor payment reconciliation</li>
              <li>Accounts receivable &amp; payable processing</li>
            </ul>

            <p>
              We may use your categories of information to aid in the
              performance of our internal business operations. This business
              purpose includes activities such as:
            </p>

            <ul>
              <li>Strategic enhancements &amp; new service development</li>
              <li>Systems implementation &amp; support services</li>
              <li>Day-to-day business operations</li>
              <li>Insurance, tax, and payroll preparation &amp; filing</li>
            </ul>

            <p>
              Please note, we use all categories only as permitted by law or as
              we may notify you. If your consent is required by law, we will
              obtain your consent prior to the use and/or collection of your
              information.
            </p>

            <h4>OUR SOURCES OF INFORMATION &amp; HOW WE COLLECT INFORMATION</h4>
            <p>We collect categories of information via multiple methods.</p>

            <p>
              You or your legal representative or authorized agent is our main
              source when collecting your information in all of the
              categories above.  We collect your information through  the
              following methods:
            </p>

            <ul>
              <li>E-mails</li>
              <li>Over the phone</li>
              <li>In-person</li>
              <li>Electronic files </li>
              <li>Paper documents</li>
              <li>Event registration forms</li>
              <li>Web forms</li>
              <li>Employment applications</li>
            </ul>

            <p>
              Our clients are another source for collecting your information
              in all of the categories above. We collect your information
              through the following methods:
            </p>

            <ul>
              <li>E-mails</li>
              <li>Over the phone</li>
              <li>In-person</li>
              <li>Electronic files </li>
              <li>Paper documents</li>
              <li>Integrations, APIs, file transfer websites.</li>
              <li>Database and data warehouse access</li>
              <li>Event registration forms</li>
            </ul>

            <p>
              Our business partners, service providers &amp; third party
              vendors represent another source for accumulating your information
              in all of the categories above. We collect your information
              through the following methods:
            </p>

            <ul>
              <li>E-mail</li>
              <li>Over the phone</li>
              <li>In-person</li>
              <li>User account portals</li>
              <li>Electronic files</li>
              <li>Paper documents</li>
              <li>Event sponsorship forms &amp; rosters</li>
              <li>Web forms</li>
              <li>
                Contracts, including service agreements and statements of work
              </li>
              <li>Integrations, APIs, File Transfer Sites</li>
            </ul>

            <p>
              Our website cookies and your interaction with our website is an
              additional source for collecting your information for three of the
              categories above: Personal Identifiers and Demographic
              Information, Internet or Network Activities, Location Data. We
              collect your information through the following methods:
            </p>

            <ul>
              <li>Tracking cookies</li>
              <li>Marketing cookies</li>
              <li>Analytic cookies</li>
              <li>Pixels</li>
              <li>Tags</li>
            </ul>

            <p>
              Publicly available sources are used to collect your information
              for the following categories: personal identifiers and demographic
              information, client relationship information, location
              data, employment information, and educational information. We
              collect your information through the following methods:
            </p>

            <ul>
              <li>Social media</li>
              <li>
                Websites (such as professional networking sites &amp; online
                searches)
              </li>
              <li>Magazine, newspaper, blogs, and other reporting media</li>
              <li>Online professional forums</li>
              <li>White papers and other peer review publications</li>
            </ul>

            <p>
              Educational institutions, professional &amp; government licensing
              groups and your interaction with these entities is an additional
              source for collecting your information for each of the following
              categories: personal identifiers and demographic information,
              location data, employment information, and educational
              information. We collect your information through the following
              methods:
            </p>

            <ul>
              <li>E-mail</li>
              <li>Over the phone</li>
              <li>In-person</li>
              <li>User account portals</li>
              <li>Paper documents</li>
              <li>Electronic files</li>
              <li>Web forms</li>
            </ul>

            <h4>WE COMBINE INFORMATION</h4>
            <p>
              We combine information we collect from you on the website with
              information we receive from you in person, by email, or by other
              means. We also combine information you provide with information we
              obtain from third parties and service providers.
            </p>

            <h4>WHEN WE SHARE INFORMATION WITH OTHERS</h4>
            <p>
              On an as-needed basis, we may share all categories of
              information above with third parties, service providers, and
              others. We share personal information with vendors who provide
              services to us. Refer to Categories of Information We Collect, to
              see the types of information that may be relevant to you.
            </p>

            <p>
              Our service providers are categorized in at least one of the
              following categories based on the services they provide us:
            </p>

            <ul>
              <li>
                Business operational services (such as payroll processing,
                recruitment, AR/AP processing, or other like services)
              </li>
              <li>
                Legal, compliance and advisory services (such as attorneys,
                financial advisors, and external consultants)
              </li>
              <li>
                Marketing, events &amp; communications (such as hosted events,
                newsletters, and email marketing)
              </li>
              <li>
                Telecommunication &amp; mobile services (such as telephone
                systems and inter-office messaging services)
              </li>
              <li>
                Network, data centers &amp; infrastructure services (such as
                internet, intranet, email, software and cloud servers)
              </li>
            </ul>

            <p>
              We share information with government and regulatory authorities in
              the course of providing services to you, such as preparation of
              tax returns. We only share information when required to in order
              to comply with law or to protect our legal interests. We may share
              information we collect about you to respond to a court order or
              subpoena. We may share information in response to a government
              agency or investigatory body request. This may include sharing
              information with the U.S. or Canadian governments. We may share
              information if necessary to prevent physical harm or financial
              loss. We may share information we collect when we are
              investigating potential fraud or other illegal activity.
            </p>

            <p>
              We share information with any successor to all or part of our
              business. If all or part of our business is sold, we will share
              information as a part of that transaction.  If there is a merger
              or acquisition, we will also share your information. We will also
              share information as part of a financing or bankruptcy.
            </p>

            <p>
              We share information as permitted by law and for other reasons we
              may describe to you.
            </p>

            <h4>YOU HAVE CERTAIN CHOICES</h4>
            <p>
              We provide you with the following options about how we use
              information:
            </p>

            <p>
              <b>EVERYONE</b>
            </p>

            <p>
              You can opt out of receiving our marketing emails at any time by
              clicking click the unsubscribe link located at the bottom of our
              emails.  If you opt out of receiving marketing messages, you will
              still receive non-marketing messages. If you object to our use of
              your information for direct marketing purposes, please email us
              at 
              <a href="mailto:privacy@darkhorse.cpa">privacy@darkhorse.cpa</a>.
            </p>

            <p>
              <b>CALIFORNIA RESIDENTS</b>
            </p>

            <p>
              As of January 1, 2020, the California Consumer Privacy Act gives
              you (or your Proxy) the right to make certain requests of Dark
              Horse CPAs regarding information that we collect about you. We
              will not discriminate against you because you have exercised any
              of your rights under the California Consumer Privacy Act.
            </p>

            <p>Your Rights under the California Consumer Privacy Act</p>

            <ul>
              <li>
                Request which categories of information we collect (which may
                also be referenced above in  Categories of Information We
                Collect)
              </li>
              <li>
                Request which categories of service providers and third parties
                we share your information with
              </li>
              <li>
                Request a copy of your information we collected be provided to
                you
              </li>
              <li>Request that your information be deleted</li>
            </ul>

            <p>
              <b>Your California Privacy Rights</b>
            </p>

            <p>
              Pursuant to California’s “Shine The Light” law, California
              residents are entitled to request and obtain certain information
              regarding our disclosure, if any, of certain categories of
              personal information to third parties for their own direct
              marketing  purposes in the preceding calendar year. This request
              is available once per year, free of charge. You may request this
              disclosure information at{" "}
              <a href="mailto:privacy@darkhorse.cpa">privacy@darkhorse.cpa</a>{" "}
              and indicate in the email subject line “ California Shine The
              Light Request” so that we can process your request. Please include
              your mailing address, state of residence and email address with
              your request.
            </p>

            <p>
              <b>Our Verification Process</b>
            </p>

            <p>
              Before we can begin to process your request, we must first verify
              your identity. We will use the following points of information for
              our verification process.
            </p>

            <ul>
              <li>Full Name</li>
              <li>E-mail Address</li>
              <li>Phone Number</li>
              <li>Mailing Address</li>
              <li>
                Employer, service provider, third party vendor or organization’s
                name you are an agent of
              </li>
            </ul>

            <p>
              Requests made for your categories of information require that we
              verify your identity using at least two data points listed above.
              Additionally, you will be required to provide a document signed
              under penalty of perjury affirming that you are indeed the
              consumer subject to the information request.
            </p>

            <p>
              For requests to delete information, we require two or three of the
              points of information above, depending on the sensitivity of the
              information to be deleted.
            </p>

            <p>
              Proxy Requests. Requests made by a California resident’s proxy
              will follow the same verification process as above. Additionally,
              a proxy is required to provide to us a notarized attestation that
              they are the California resident’s authorized legal
              representative.
            </p>

            <p>
              How To Make A Request. You or your proxy may make a request by
              emailing us at{" "}
              <a href="mailto:privacy@darkhorse.cpa">privacy@darkhorse.cpa</a>. 
              A customer service representative will collect the necessary
              information from you and provide you with details about how you
              can provide us with any additional documentation that may be
              necessary.  
            </p>

            <p>
              Sales of Information of California Resident. We do not and will
              not sell California residents’ personal information.
            </p>

            <p>
              <b>Children’s Privacy</b>
            </p>

            <p>
              We do not knowingly collect, use, or disclose personal information
              from children under the age of 13 without prior parental consent,
              except as permitted by the Children’s Online Privacy Protection
              Act (“COPPA”). If you are a parent or guardian of a child under
              age 13 and believe we collected information in a manner not
              permitted by COPPA, please email us at 
              <a href="mailto:privacy@darkhorse.cpa">privacy@darkhorse.cpa</a> 
            </p>

            <p>
              <b>Data Security</b>
            </p>

            <p>
              We maintain strict security procedures to help protect against
              loss, misuse, unauthorized access, disclosure, alteration or
              destruction of the information you provide to us.  No data
              transmission or storage, however, can be guaranteed to be 100%
              secure.  As a result, we cannot guarantee the security of any
              information you disclose or transmit to us online and cannot be
              held responsible for the theft, destruction, or inadvertent
              disclosure of your information.
            </p>

            <p>
              You are responsible for maintaining the confidentiality of your
              account password and for any access to or use of our services
              using your password, whether or not authorized by you.  Please
              notify us immediately if you suspect any unauthorized use of your
              account or any other breach of security.
            </p>

            <p>
              <b>Privacy Policy Updates</b>
            </p>

            <p>
              We may modify this Privacy Policy at any time by posting revisions
              here.
            </p>

            <p>
              <b>Contact Us</b>
            </p>

            <p>
              If you have any questions about this Privacy Policy, you may
              contact us at:
            </p>

            <p>
              By Email: 
              <a href="mailto:privacy@darkhorse.cpa">
                privacy@darkhorse.cpa
              </a>{" "}
              Subject: “Privacy Policy Questions”
            </p>

            <p>
              <b>By Mail:</b>
            </p>
            <p>
              Dark Horse CPAs, An Accountancy Corporation
              <br />
              2033 San Elijo Ave #148, Cardiff by the Sea, CA 92007
            </p>
          </StyledContent>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
